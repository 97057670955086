import React from 'react'
import { graphql, Link } from 'gatsby'
import get from 'lodash/get'

import Layout from '../components/App/Layout'
import Lightbox from 'react-image-lightbox'

const HotelIndex =(props)=> {
 
    const posts = get(props, 'data.allContentfulAsset.nodes')
    const [isOpen, setIsOpen] = React.useState(false)
    const [photoIndex, setPhotoIndex] = React.useState(0)
    const [src, setSrc] = React.useState('')
    //alert(JSON.stringify(posts))

    const imagesItems = posts.map((image, index) => (
      <div className="col-sm-6 col-lg-4" key={index}>
        <div className="work-details">
          <img src={image.url} alt="sample" />
          <div className="box-content">
            <h3 className="title">{image.title}</h3>
            <ul className="icon">
              <li>
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault()
                    setSrc(
                      `https://d1gq3gv99zt38n.cloudfront.net/510x600/${image.filename}`
                    )
                    setPhotoIndex(0)
                    setIsOpen(true)
                  }}
                >
                  <i className="fa fa-link"></i>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    ))

    return (
      <Layout location="blog">
        <div className="bread-cumbs-area bread-cumbs-bg-rooms">
          <div className="diplay-table">
            <div className="display-table-cell">
              <div className="container">
                <div className="row">
                  <div className="col-lg-7">
                    <h1>{`Les suites et appartements disponibles à TALI N'NA`}</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section id="rooms" className="our-works ptb-10">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2 text-center">
                <div className="section-title">
                  <h2>Galerie de l'Hôtel</h2>
                  <span className="section-title-bg">Photos des chambres</span>
                </div>
              </div>
            </div>

            <div id="Portfolio" className="row">
              {imagesItems}
            </div>
          </div>
        </section>
        {isOpen && (
          <Lightbox
            image={src}
            startIndex={photoIndex}
            onClose={() => setIsOpen(false)}
          />
        )}
      </Layout>
    )
  }

export default HotelIndex

export const pageQuery = graphql`
  query RoomsIndexQuery {
    allContentfulAsset(
      filter: { metadata: { tags: { elemMatch: { name: { eq: "Room" } } } } }
    ) {
      nodes {
        url
        title
        filename
      }
      totalCount
    }
  }
`
